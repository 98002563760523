
import { Buttons, Inputs, TableColumns } from "@/types/BasicComponent";
import { formatDate, formatTime, getTableColumnsSummary } from "@/utils/common";
import { defineComponent, reactive, ref, toRef } from "vue";
import BasicTableDialog from '@/components/BasicPageLayout/BasicTableDialog.vue'
import { getJhbcAccountFlow, QueryJhbcAccountFlowParams } from "@/api/jhbc";
import usePagination from "@/utils/composables/usePagination";
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'

export default defineComponent({
  components: {
    BasicTableDialog,
    BasicTablePrintAndExport
  },
  setup() {
    const modalShow = ref(false)
    const pagination = usePagination()
    const corpName = ref('')
    const searchData = reactive<QueryJhbcAccountFlowParams>({
      jhbId: 3
    })

    const show = (plywoodCode: number, jhbName: string) => {
      modalShow.value = true
      corpName.value = jhbName
      searchData.jhbId = plywoodCode
      getTableData()
    }

    const getTableData = async () => {
      const respData = await getJhbcAccountFlow(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = respData.data.data.records
      pagination.total = respData.data.data.total
      tableData.value.forEach((td, index) => td.index = pagination.pageOffset + index)
    }

    pagination.setCallback(getTableData)

    const exportRef = ref()

    const searchInputs = reactive<Inputs>([
      {
        type: 'select',
        label: '流水类型',
        model: toRef(searchData, 'flowType'),
        options: [
          { label: '订单支付', value: 1 },
          { label: '服务费扣减', value: 2 },
          { label: '充值', value: 3 },
          { label: '退款', value: 4 },
          { label: '货款退款', value: 7 },
          { label: '服务费退款', value: 8 },
          { label: '货款退款提现', value: 9 },
          { label: '服务费退款提现', value: 10 },
        ]
      },
      {
        type: 'group',
        divider: '-',
        label: '日期',
        inputs: [
          {
            type: 'date',
            placeholder: '开始日期',
            model: toRef(searchData, 'createTimeBegin')
          }, {
            type: 'date',
            placeholder: '结束日期',
            model: toRef(searchData, 'createTimeEnd')
          }
        ]
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => exportRef.value.doExport()
      }
    ])

    const tableData = ref<AnyArray>([])
    
    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        label: '序号',
        width: '70px',
        align: 'center',
      }, {
        prop: 'flowType',
        label: '类型',
        minWidth: '150px',
        align: 'center',
        type: 'status',
        statusType: [
          { label: '货单支付', value: 1 },
          { label: '服务费扣减', value: 2 },
          { label: '充值', value: 3 },
          { label: '退款', value: 4 },
          { label: '货款退款', value: 7 },
          { label: '服务费退款', value: 8 },
          { label: '货款退款提现', value: 9 },
          { label: '服务费退款提现', value: 10 },
        ]
      }, {
        prop: 'createTime',
        label: '时间',
        minWidth: '150px',
        type: 'render',
        align: 'center',
        render: scope => formatTime(scope.row.createTime)
      }, {
        prop: 'amount',
        label: '变动金额',
        minWidth: '100px',
        align: 'center',
      }, {
        prop: 'balance',
        label: '余额',
        minWidth: '100px',
        align: 'center',
      }, {
        prop: 'description',
        label: '摘要',
        minWidth: '250px',
        align: 'center',
      }
    ])
    
    const mySummaryMethod = (scope: AnyObject) => {
      let props = ['amount', 'balance']
      return getTableColumnsSummary(props, scope.columns, scope.data)
    }
      
    return {
      show,
      exportRef,
      corpName,
      pagination,
      modalShow,
      tableData,
      searchInputs,
      searchButtons,
      tableColumns,
      mySummaryMethod
    }
  }
})
