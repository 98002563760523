
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { computed, defineComponent, onMounted, reactive, ref, toRef } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { getJhbcAccountList, QueryJhbcAccountListParams } from '@/api/jhbc'
import JHBAccountFlow from '@/views/jhbc/JHBAccountFlow.vue'
import * as math from "mathjs"
 
export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport,
    JHBAccountFlow
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<QueryJhbcAccountListParams>({})
    const tableData = ref<AnyArray>([])
    const badgeValues = reactive<AnyObject>({})
    const summaryData = ref<AnyArray>([0,0,0,0,0,0,0,0,0])
    summaryData.value = summaryData.value.map(() => math.chain(math.bignumber(0)))

    const selectedTableData = ref<AnyArray>([])
    const selectCount = computed(() => selectedTableData.value.length)

    const getTableData = async () => {
      const res = await getJhbcAccountList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = res.data.data.records
      pagination.total = res.data.data.total

      summaryData.value = summaryData.value.map(() => math.chain(math.bignumber(0)))

      tableData.value.forEach(td => {
        summaryData.value[0] = summaryData.value[0].add(td.beginBalanceAmount)
        summaryData.value[1] = summaryData.value[1].add(td.rechargeAmount)
        summaryData.value[2] = summaryData.value[2].add(td.applyOrderPriceAmount)
        summaryData.value[3] = summaryData.value[3].add(td.payOrderPriceAmount)
        summaryData.value[4] = summaryData.value[4].add(td.unPayOrderPriceAmount)
        summaryData.value[5] = summaryData.value[5].add(td.serviceDeductionAmount)
        summaryData.value[6] = summaryData.value[6].add(td.endBalanceAmount)
        summaryData.value[7] = summaryData.value[7].add(td.invoicedAmount)
        summaryData.value[8] = summaryData.value[8].add(td.unInvoiceAmount)
      })
    }

    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
    })

    const onSelectionChange = (selection: any[]) => selectedTableData.value = selection

    const exportRef = ref()
    const jhbAccountFlowRef = ref()

    const getSummaries = (param: AnyObject) => {
      const columnIndexsToSummary = [8,9,10,11,12,13,14,15,16]

      const summaryArray: AnyArray = []
      columnIndexsToSummary.forEach((idx, i) => {
        summaryArray[idx] = summaryData.value[i].done().toFixed(2)
      })

      summaryArray[0] = '本页小计'

      return summaryArray
    }

    const searchInputs = reactive<Inputs>([
      {
        label: '胶合板厂名',
        type: 'input',
        placeholder: '请输入生产机主名',
        clearable: true,
        width: '200px',
        model: toRef(searchData, 'corpName')
      }, {
        label: '是否含税',
        type: 'select',
        placeholder: '请选择',
        clearable: true,
        width: '150px',
        model: toRef(searchData, 'isTaxIncluded'),
        options: [
          { label: '是', value: 1 },
          { label: '否', value: 2 }
        ]
      }, {
        label: '起止时间',
        type: 'group',
        divider: '-',
        inputs: [
          {
            type: 'date',
            placeholder: '开始时间',
            width: '150px',
            model: toRef(searchData, 'createTimeBegin')
          }, {
            type: 'date',
            placeholder: '结束时间',
            width: '150px',
            model: toRef(searchData, 'createTimeEnd')
          }
        ]
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => {
          exportRef.value.doExport()
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '100px',
        align: 'center',
        fixed: 'left',
        index: idx => pagination.pageOffset + idx
      }, {
        prop: 'accountId',
        label: '企业平台码',
        minWidth: '120px',
        align: 'center',
        fixed: 'left',
        preventFormat: true
      }, {
        prop: 'corpName',
        label: '企业名称',
        minWidth: '200px',
        align: 'center',
        fixed: 'left',
      }, {
        prop: 'invoiceTitle',
        label: '发票抬头',
        minWidth: '200px',
        align: 'center'
      }, {
        prop: 'licenseNo',
        label: '企业统一代码',
        minWidth: '200px',
        align: 'center',
        preventFormat: true
      }, {
        prop: 'serviceFeeRate',
        label: '服务费占比(%)',
        minWidth: '200px',
        align: 'center'
      }, {
        type: 'status',
        prop: 'isTextIncluded',
        label: '是否含税',
        minWidth: '100px',
        align: 'center',
        statusType: [
          { label: '是', value: 1, type: 'success' },
          { label: '否', value: 2, type: 'warning' }
        ]
      }, {
        type: 'status',
        prop: 'invoiceChargeMode',
        label: '服务费收取方式',
        minWidth: '150px',
        align: 'center',
        statusType: [
          { label: '支付时扣除', value: 1, type: 'success' },
          { label: '开票时扣除', value: 2, type: 'warning' },
        ]
      }, {
        prop: 'beginBalanceAmount',
        label: '期初余额',
        minWidth: '200px',
        align: 'center'
      }, {
        prop: 'rechargeAmount',
        label: '充值金额',
        minWidth: '200px',
        align: 'center'
      }, {
        prop: 'applyOrderPriceAmount',
        label: '已申请货费',
        minWidth: '200px',
        align: 'center'
      }, {
        prop: 'payOrderPriceAmount',
        label: '已支付货费',
        minWidth: '200px',
        align: 'center'
      }, {
        prop: 'unPayOrderPriceAmount',
        label: '已申请未支付货费',
        minWidth: '200px',
        align: 'center'
      }, {
        prop: 'serviceDeductionAmount',
        label: '线上已扣服务费',
        minWidth: '200px',
        align: 'center'
      }, {
        prop: 'endBalanceAmount',
        label: '期末余额',
        minWidth: '200px',
        align: 'center'
      }, {
        prop: 'invoicedAmount',
        label: '已开票金额',
        minWidth: '200px',
        align: 'center'
      }, {
        prop: 'unInvoiceAmount',
        label: '未开发票金额',
        minWidth: '200px',
        align: 'center'
      }, {
        type: 'button',
        label: '操作',
        fixed: 'right',
        buttons: [
          {
            label: '明细',
            onClick: scope => {
              jhbAccountFlowRef.value.show(scope.row.corpId, scope.row.corpName)
            }
          }
        ]
      }
    ])

    return {
      getTableData,
      onSelectionChange,
      getSummaries,
      jhbAccountFlowRef,
      searchInputs,
      searchButtons,
      tableColumns,
      pagination,
      badgeValues,
      tableData,
      UserRole,
      selectCount,
      exportRef
    }
  }
})
